<script>
import axios from "axios";

import Layout from "../../layouts/auth";
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import { mapState } from "vuex";

import appConfig from "@/app.config";
import { required, username, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { useQuasar } from 'quasar'
import { useToast } from "vue-toastification";
const toast = useToast()






/**
 * Login component
 */
export default {
  setup() {
    const $q = useQuasar()
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {

      errorMessage: "",
      username: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    username: {
      required: helpers.withMessage("Please enter username.", required),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },

  mounted() {
    document.body.classList.add("auth-body-bg");

  },
  methods: {


    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {


        axios.defaults.headers.common["Authorization"] = ""



        axios
          .post("/api/jwt/create/", {
            username: this.username,
            password: this.password,
          })
          .then((response) => {

            const access = response.data.access
            const refresh = response.data.refresh

            axios.defaults.headers.common["Authorization"] = "JWT " + access

            localStorage.setItem("access", access)
            localStorage.setItem("refresh", refresh)

            this.$store.commit("initializeSS")

            axios.all([
              //retrieves user details
              axios
                .get('/api/users/me/')
                .then(response => {



                  const user = response.data
           



                  this.$store.commit('setUser', user)



                }),
              


            ])



            this.$router.push('/')

          })
          .catch((error) => {

            let errorMessages = "";
              for (let key in error.response.data) {
                errorMessages += key + ": " + error.response.data[key] + "\n";
              }
     

            
            toast.error(`${errorMessages}`,
         {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          icon: 'error',
         }
      );


          });

      }
    },
  },

};
</script>
<style>
.full-screen {
  height: 100%;
  position: absolute;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  /* filter: grayscale(100%);
-webkit-filter: grayscale(100%); */
}
</style>
<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-xl-9">
          <div class=" auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div id="myVideo" class= "bg-overlay"></div>


              <!-- <div class="d-flex h-100 flex-column">
                <div class="row justify-content-center">
                  <div class="col-sm-7 align-items-xl-center ">
                    <img class="rounded-circle avatar-lg w-100 h-100 " alt="200x200"
                      src="@/assets/images/sabongfight.png" data-holder-rendered="true" />
                  </div>
                </div>
                <div class="p-4 mt-auto">


                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
          <div class="auth-full-page-content p-md-4 p-5">
            <div class="w-100">
              <div class="d-flex flex-column h-100">

                <div class="my-auto">
                  <div class="row justify-content-center">
                    <div class="col-xl-5 col-xl-5 col-xl-11">
                      <div class="card overflow-hidden">
                        <div class="bg-soft bg-primary">
                          <div class="row">
                            <div class="col-9">
                            
                                <h5 class="text-white  mx-3 my-3">Welcome Back !</h5><br>
                               
                          
                            </div>
                            <div class="col-3 my-3 align-self-end">
                              <img src="@/assets/images/logos/DO.png" width="80" height="80" alt class="img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div class="card-body pt-0">
                          <div>
                            <router-link to="/">
                              <div class="avatar-md profile-user-wid mb-0">
                                <span class="avatar-title rounded-circle bg-transparent">
                                  <img src="@/assets/images/logos/sitelogo.png" alt class="img-fluid" height="80" width="80"  />
                                </span>
                              </div>
                            </router-link>
                          </div>


                          <b-form class="p-2" @submit.prevent="tryToLogIn">
                            <b-form-group class="mb-3" id="input-group-1" label="username" label-for="input-1">
                              <b-form-input id="input-1" v-model="username" type="text" placeholder="Enter username"
                                :class="{
                                  'is-invalid': submitted && v$.username.$error,
                                }"></b-form-input>
                              <div v-for="(item, index) in v$.username.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                            </b-form-group>

                            <b-form-group class="mb-3" id="input-group-2" label="Password" label-for="input-2">
                              <b-form-input id="input-2" v-model="password" type="password" placeholder="Enter password"
                                :class="{
                                  'is-invalid': submitted && v$.password.$error,
                                }"></b-form-input>
                              <div v-if="submitted && v$.password.$error" class="invalid-feedback">
                                <span v-if="v$.password.required.$message">{{
                                    v$.password.required.$message
                                }}</span>
                              </div>
                            </b-form-group>
                            <b-form-checkbox class="form-check me-2 mt-0" id="customControlInline" name="checkbox-1"
                              value="accepted" unchecked-value="not_accepted">Remember me
                            </b-form-checkbox>
                            <div class="mt-3 d-grid">
                              <b-button type="submit" variant="primary" class="btn-block">Log In</b-button>
                            </div>
                            <div v-for="(error, index) in errorMessage">

                              {{ error }}




                            </div>

                            <!-- <div class="mt-3 text-center">
                              <h5 class="font-size-14 mb-3">Sign in with</h5>

                              <ul class="list-inline">
                                <li class="list-inline-item">
                                  <a href="javascript: void(0);" class="
                        social-list-item
                        bg-primary
                        text-white
                        border-primary
                      ">
                                    <i class="mdi mdi-facebook"></i>
                                  </a>
                                </li>
                                <li class="list-inline-item">
                                  <a href="javascript: void(0);"
                                    class="social-list-item bg-info text-white border-info">
                                    <i class="mdi mdi-twitter"></i>
                                  </a>
                                </li>
                                <li class="list-inline-item">
                                  <a href="javascript: void(0);" class="
                        social-list-item
                        bg-danger
                        text-white
                        border-danger
                      ">
                                    <i class="mdi mdi-google"></i>
                                  </a>
                                </li>
                              </ul>
                            </div> -->
                       
                          </b-form>
                          <!-- <div class="mt-2 text-center mb-2">
                              <router-link to="/forgot-password" class="text-muted">
                                <i class="mdi mdi-lock me-1"></i> Forgot your password?
                              </router-link>
                            </div> -->
                          <div class="mt-0 text-center">
                        <p>
                          Don't have an account ?
                          <router-link to="/register" class="fw-medium text-primary">Signup now</router-link>
                        </p>
                        <p>
                          © {{ new Date().getFullYear() }} D.O.T. V1.6 - CT
                        </p>
                      </div>
                        </div>
                        <!-- end card-body -->
                      </div>
                      <!-- end card -->

                  
                      <!-- end row -->
                    </div>
                    <!-- end col -->
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container-fluid -->

</template>
